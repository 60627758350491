import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
// material
import { Box, Typography } from '@mui/material'
// ----------------------------------------------------------------------
import { useMe } from '../Context/MeContext'
import { useSettings } from '../Context/SettingsContext'

Logo.propTypes = {
  sx: PropTypes.object
}

export default function Logo({ sx }) {
  const { role } = useMe()
  const { settingsInfo } = useSettings()

  const navigate = useNavigate()

  const checkAuth = () => {
    if (role === 'admin') {
      return navigate('/app/dashboard')
    }
    if (role === 'egresso') {
      return navigate('/app/profile')
    }
    return navigate('/')
  }

  if (!settingsInfo?.department_image && settingsInfo?.department_acronym)
    return (
      <Typography variant="button" component="span" sx={{ lineHeight: 1 }}>
        {settingsInfo?.department_acronym}
      </Typography>
    )

  return (
    <Box
      style={{ cursor: 'pointer' }}
      component="img"
      src={settingsInfo?.department_image || '/static/logo_meus_egressos.png'}
      sx={{ maxHeight: 60, maxWidth: { xs: 125, md: 200 }, ...sx }}
      onClick={checkAuth}
    />
  )
}
