import { motion } from 'framer-motion'
import { Link as RouterLink } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
import { Box, Button, Typography, Container } from '@mui/material'
// components
import { MotionContainer, varBounceIn } from '../components/animate'
import Page from '../components/Page'
import { useMe } from '../Context/MeContext'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}))

// ----------------------------------------------------------------------

export default function Page404() {
  const { role } = useMe()

  const redirect = () => {
    if (role === 'admin') return '/app/dashboard'
    if (role === 'egresso') return 'app/profile'
    return '/mapa'
  }
  return (
    <RootStyle title="404 Página não encontrada">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Desculpe, página não encontrada!
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Desculpe, não conseguimos encontrar a página que você está procurando. Talvez você tenha digitado
              incorretamente o URL? Certifique-se de verificar sua ortografia.
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/illustrations/illustration_404.svg"
                sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
              />
            </motion.div>

            <Button
              to={role === 'admin' ? '/app/dashboard' : '/app/profile'}
              size="large"
              variant="contained"
              component={RouterLink}
            >
              Ir para Início
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  )
}
