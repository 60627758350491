import { useEffect } from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
import { Link, Container, Typography } from '@mui/material'
// layouts
// components
import Page from '../components/Page'
import AuthSidebar from '../layouts/AuthSidebar'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}))

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!location.state) {
      navigate('/login', { redirect: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RootStyle title="Redefinir Senha">
      <AuthSidebar>
        <Typography variant="h4" sx={{ px: 10 }} align="center">
          Bem vindo,
        </Typography>
        <Typography variant="h1" sx={{ px: 10 }} align="center">
          Portal Egressos
        </Typography>
      </AuthSidebar>

      <Container maxWidth="md">
        <ContentStyle>
          <Typography variant="h2" align="center" sx={{ mb: 3 }}>
            E-mail enviado
          </Typography>
          <Typography variant="h4" component="p" align="center" sx={{ mb: 3, fontWeight: '400' }}>
            Enviamos sua nova senha por e-mail.
          </Typography>
          <Typography variant="h4" component="p" align="center" sx={{ mb: 3, fontWeight: '400' }}>
            Verifique a caixa de entrada do seu E-mail. Caso não localize nosso E-mail verifique sua caixa de spam.
          </Typography>
          <Typography variant="h4" component="p" align="center" sx={{ mb: 3, fontWeight: '400' }}>
            Para acessar a plataforma{' '}
            <Link variant="h4" component={RouterLink} to="/login" sx={{ fontWeight: '400' }}>
              clique aqui
            </Link>
            .
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  )
}
