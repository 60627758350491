import React from 'react'
// material
import { Box, Grid, Container, Typography } from '@mui/material'
// components
import Page from '../components/Page'

import {
  AppConversionRates,
  AppCurrentSubject,
  AppNewsUpdate,
  AppOrderTimeline,
  AppTasks,
  AppTrafficBySite
} from '../components/_dashboard/app'
import { useMe } from '../Context/MeContext'
import TotalEgressos from '../components/_dashboard/app/main/TotalEgressos'
import TotalEgressosMestrado from '../components/_dashboard/app/main/TotalEgressosMestrado'
import TotalEgressosDoutorado from '../components/_dashboard/app/main/TotalEgressosDoutorado'
import JobAreaPizza from '../components/_dashboard/app/main/JobAreaPizza'
import TotalEgressosAposentado from '../components/_dashboard/app/main/TotalEgressosAposentado'
import EgressosByCountry from '../components/_dashboard/app/main/EgressosByCountry'
import EgressosByStatesBrasil from '../components/_dashboard/app/main/EgressosByStatesBrasil'
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { userInfo } = useMe()
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Olá {userInfo?.name}, bem vindo de volta! 👋</Typography>
        </Box>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={6} md={3}>
            <NavLink to="/app/egressos" replace style={{ textDecoration: 'none' }}>
              <Egressos />
            </NavLink>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NavLink to="/app/usuarios" replace style={{ textDecoration: 'none' }}>
              <Usuarios />
            </NavLink>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NavLink to="/app/areas-atuacao" replace style={{ textDecoration: 'none' }}>
              <AreasAtuacao />
            </NavLink>
          </Grid> */}

          <Grid item xs={12} md={3}>
            <TotalEgressos />
          </Grid>

          <Grid item xs={12} md={3}>
            <TotalEgressosMestrado />
          </Grid>

          <Grid item xs={12} md={3}>
            <TotalEgressosDoutorado />
          </Grid>

          <Grid item xs={12} md={3}>
            <TotalEgressosAposentado />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <JobAreaPizza />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <EgressosByCountry />
          </Grid>

          <Grid item xs={12}>
            <EgressosByStatesBrasil />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  )
}
