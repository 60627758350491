import * as Yup from 'yup'
import React, { useState } from 'react'
import { useFormik, Form, FormikProvider } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { Card, Stack, Container, TextField, Alert, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import Page from '../../Page'

import { createAreaAtuacao, updateAreaAtuacao } from './requests'

const AreaAtuacaoForm = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [name, setName] = useState(location.state?.row.name || '')
  const [reqErr, setReqErr] = useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const handleSnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant
    })
  }

  const EditSchema = Yup.object().shape({
    name: Yup.string().required('Por favor informe o nome da Área de atuação').nullable()
  })

  const formik = useFormik({
    initialValues: {
      name
    },
    validationSchema: EditSchema,
    onSubmit: async () => {
      if (location.state?.areaAtuacaoId) {
        try {
          await updateAreaAtuacao(location.state?.areaAtuacaoId, values)
          handleSnackBar('Área de atução editada com sucesso!', 'success')
          navigate('/app/areas-atuacao', { state: { page: location?.state?.page }, replace: true })
        } catch (error) {
          if (error.response.data.errors) {
            setErrors(error.response.data.errors)
          } else {
            handleSnackBar('Erro ao editar área de atuação!', 'error')
            setReqErr(true)
          }
        }
        return
      }

      try {
        await createAreaAtuacao(values)
        handleSnackBar('Área de atuação criada com sucesso!', 'success')
        navigate('/app/areas-atuacao')
      } catch (error) {
        if (error.response.data.errors) {
          setErrors(error.response.data.errors)
        } else {
          handleSnackBar('Erro ao criar área de atuação!', 'error')
          setReqErr(true)
        }
      }

      setReqErr(false)
    }
  })

  const printButtonText = () => {
    if (location.state?.areaAtuacaoId) return 'Editar'
    return 'Criar'
  }

  const { errors, values, setErrors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik
  return (
    <Page title="Criar Área de atução">
      <Container>
        <Card style={{ padding: '30px' }}>
          <FormikProvider value={formik}>
            {reqErr && (
              <Alert variant="filled" severity="error" style={{ marginBottom: '32px' }}>
                Ocorreu um erro inesperado, por favor tente novamente mais tarde.
              </Alert>
            )}

            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nome Completo"
                    {...getFieldProps('name')}
                    inputProps={{ type: 'string', value: name }}
                    error={Boolean(touched?.name && errors?.name)}
                    helperText={touched?.name && <span dangerouslySetInnerHTML={{ __html: errors?.name }} />}
                    onChange={(e) => {
                      setFieldValue('name', e.target.value)
                      setName(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
                      {printButtonText()}
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Card>
      </Container>
    </Page>
  )
}

export default AreaAtuacaoForm
