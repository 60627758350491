import Api from './api'

export const dashboardApi = {
  getTotalEgressos: async () => {
    const response = await Api.get('/dashboard/egressos/total')
    return response.data
  },
  getTotalEgressosMestrado: async () => {
    const response = await Api.get('/dashboard/egressos/mestrado/total')
    return response.data
  },
  getTotalEgressosDoutorado: async () => {
    const response = await Api.get('/dashboard/egressos/doutorado/total')
    return response.data
  },

  getTotalEgressosAposentado: async () => {
    const response = await Api.get('/dashboard/egressos/aposentado/total')
    return response.data
  },

  getTotalJobArea: async () => {
    const response = await Api.get('/dashboard/egressos/total/job-area')
    return response.data
  },

  getTotalCountry: async () => {
    const response = await Api.get('/dashboard/egressos/country/total')
    return response.data
  },

  getTotalBrasilStates: async () => {
    const response = await Api.get('/dashboard/egressos/country/brasil/states/total')
    return response.data
  }
}
