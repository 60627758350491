import React from 'react'
import { merge } from 'lodash'
import ReactApexChart from 'react-apexcharts'
// material
import { useTheme, styled } from '@mui/material/styles'
import { Card, CardHeader } from '@mui/material'
// utils
import { fNumber } from '../../../../utils/formatNumber'
//
import { BaseOptionChart } from '../../../charts'
import { dashboardApi } from '../../../../api/dashboard'

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372
const LEGEND_HEIGHT = 72

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}))

// ----------------------------------------------------------------------

export default function JobAreaPizza() {
  const theme = useTheme()

  const [chartData, setChartData] = React.useState([])

  React.useEffect(() => {
    getChartData()
  }, [])

  async function getChartData() {
    try {
      const resp = await dashboardApi.getTotalJobArea()
      setChartData([resp.publica, resp.privada])
    } catch (error) {
      console.error(error)
    }
  }

  const chartOptions = merge(BaseOptionChart(), {
    colors: [theme.palette.primary.main, theme.palette.info.main],
    labels: ['Pública', 'Privada'],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `#${seriesName}`
        }
      }
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } }
    }
  })

  return (
    <Card>
      <CardHeader title="Área de Trabalho" />
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="pie" series={chartData} options={chartOptions} height={280} />
      </ChartWrapperStyle>
    </Card>
  )
}
