import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getSettingsInfo } from '../api/settings'
import LoadingPage from '../components/LoadingPage'

const SettingsContext = createContext()

export const SettingsProvider = ({ children }) => {
  const [settingsInfo, setSettingsInfo] = useState()
  const [settingsLoading, setSettingsLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const fetchSettingsData = async () => {
    try {
      setSettingsLoading(true)
      const settings = await getSettingsInfo()
      setSettingsInfo(settings?.data)
      setSettingsLoading(false)
    } catch (error) {
      setSettingsLoading(false)
      navigate('/mapa', { replace: true, state: { from: location } })
    }
  }

  useEffect(() => fetchSettingsData(), [refresh])

  if (settingsLoading) {
    return <LoadingPage />
  }

  const value = {
    settingsInfo,
    setSettingsInfo,
    settingsLoading,
    setRefresh
  }
  return (
    <div>
      <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>
    </div>
  )
}

export const useSettings = () => {
  return useContext(SettingsContext)
}
