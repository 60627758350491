/* eslint-disable react/no-danger */
/* eslint-disable camelcase */
import * as Yup from 'yup'
import { useState, useEffect } from 'react'
import { useFormik, Form, FormikProvider } from 'formik'
import { useNavigate } from 'react-router-dom'
// material
import {
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Alert
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
// ----------------------------------------------------------------------
import { registerEgresso } from '../../../api/auth'

import GoogleSearch from '../../googleInput/GooglePlaces'
import ComboBox from '../../ComboBox'

import { getNotPaginatedAreasAtuacao } from '../../_dashboard/areasAtuacao/requests'
import useDebounce from '../../../utils/useDebounce'
import { useSettings } from '../../../Context/SettingsContext'

export default function RegisterForm() {
  const { settingsInfo } = useSettings()
  const navigate = useNavigate()
  const [checkBox, setCheckBox] = useState(0)
  const [permission, setPermission] = useState(0)
  const [reqErr, setReqErr] = useState(false)

  const [doubleCheckbox, setDoubleCheckbox] = useState(0)

  const [open, setOpen] = useState(false)
  const [occupations, setOccupations] = useState([])
  const [occupationQuery, setOccupationQuery] = useState('')
  const [loadingOccupations, setLoadingOccupations] = useState(false)
  const [areaActivity, setAreaActivity] = useState(null)

  const debouncedQuery = useDebounce(occupationQuery, 300)

  const { department_name: department, university_acronym: university } = settingsInfo || {}
  const labelMestradoField = `Ano de formação no ${department ? `mestrado em ${department}-${university}` : 'mestrado'}`
  const labelDoutoradoField = `Ano de formação no ${
    department ? `doutorado em ${department}-${university}` : 'doutorado'
  }`

  // Pegando as areas de atuacao para a combo box
  useEffect(() => {
    if (checkBox === 1 && (open || debouncedQuery)) {
      ;(async () => {
        setLoadingOccupations(true)
        const { data } = await getNotPaginatedAreasAtuacao(occupationQuery)
        if (data?.length) setOccupations(data)
        setLoadingOccupations(false)
      })()
    }
  }, [debouncedQuery, open])

  useEffect(() => {
    if (!open && checkBox === 1) {
      setOccupations([])
    }
  }, [open])

  const RegisterSchema = Yup.object().shape({
    full_name: Yup.string().required('Nome completo obrigatório'),
    masters_degree_year: Yup.string().min(4, 'Ano inválido').max(4, 'Ano inválido'),
    doctorate_degree_year: Yup.string().min(4, 'Ano inválido').max(4, 'Ano inválido'),
    is_employed: Yup.string(),
    job_area: checkBox === 'Y' ? Yup.string().required('Selecione uma área') : Yup.string(''),
    area_activity_id: Yup.number().nullable(),
    job_company: checkBox === 'Y' ? Yup.string().required('Informe o nome da empresa ou instituição') : Yup.string(''),
    email: Yup.string().email('O e-mail deve ser um endereço de e-mail válido').required('E-mail obrigatório'),
    job_state:
      checkBox === 'Y' ? Yup.string().required('Estado obrigatório').typeError('Estado obrigatório') : Yup.string(),
    job_city:
      checkBox === 'Y' ? Yup.string().required('Cidade obrigatória').typeError('Cidade obrigatória') : Yup.string(),
    allows_display_data_publicly: Yup.number()
  })

  const formik = useFormik({
    initialValues: {
      full_name: '',
      masters_degree_year: '',
      doctorate_degree_year: '',
      email: '',
      allows_display_data_publicly: 0,
      is_employed: 'N',
      job_state: '',
      job_city: '',
      job_company: '',
      job_area: '',
      area_activity_id: null,
      job_country: '',
      is_retired: 0,
      lat: 0,
      lng: 0
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      setReqErr(false)
      try {
        await registerEgresso(values)
        navigate('/registro-concluido', { state: { email: values.email }, replace: true })
        setReqErr(false)
      } catch (error) {
        if (error.response.status !== 422) {
          setReqErr(true)
        }
        setErrors(error.response.data.errors)
      }
    }
  })

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, setErrors } = formik

  // Handler da checkbox "Está empregado atualmente?"
  const handleChange = (e) => {
    if (e.target.checked) {
      setDoubleCheckbox(1)
      setCheckBox(1)
      setFieldValue('is_employed', 'yes')
    } else {
      setFieldValue('job_area', '')
      setFieldValue('area_activity_id', null)
      setFieldValue('job_company', '')
      setFieldValue('job_country', '')
      setFieldValue('job_state', '')
      setFieldValue('job_city', '')
      setFieldValue('allows_display_data_publicly', 0)
      setDoubleCheckbox(0)
      setCheckBox(0)
      setFieldValue('is_employed', 'N')
      setFieldValue('lat', 0)
      setFieldValue('lng', 0)
    }
  }

  // Permição da exibição dos dados publicamente
  const handlePermission = () => {
    const perm = permission === 0 ? 1 : 0
    setPermission(perm)
    setFieldValue('allows_display_data_publicly', perm)
  }

  const handleCheckbox = (e) => {
    setFieldValue('is_employed', 'N')
    if (e.target.checked) {
      setCheckBox(1)
      setDoubleCheckbox(2)
      setFieldValue('is_retired', 1)
    } else {
      setCheckBox(0)
      setDoubleCheckbox(0)
      setFieldValue('is_retired', 0)
      setFieldValue('lat', 0)
      setFieldValue('lng', 0)
      setFieldValue('job_country', '')
      setFieldValue('job_state', '')
      setFieldValue('job_city', '')
      setFieldValue('job_company', '')
    }
  }

  return (
    <FormikProvider value={formik}>
      {reqErr && (
        <Alert variant="filled" severity="error" style={{ margin: '32px 0' }}>
          Ocorreu um erro inesperado, por favor tente novamente mais tarde.
        </Alert>
      )}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Nome Completo"
            {...getFieldProps('full_name')}
            inputProps={{ type: 'string' }}
            error={Boolean(touched?.full_name && errors?.full_name)}
            helperText={touched.full_name && <span dangerouslySetInnerHTML={{ __html: errors?.full_name }} />}
          />

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="E-mail"
            {...getFieldProps('email')}
            error={Boolean(touched?.email && errors?.email)}
            helperText={touched?.email && <span dangerouslySetInnerHTML={{ __html: errors?.email }} />}
            onChange={(e) => {
              setFieldValue('email', e.target.value)
            }}
          />

          <TextField
            fullWidth
            inputProps={{ type: 'number' }}
            label={labelMestradoField}
            {...getFieldProps('masters_degree_year')}
            error={Boolean(touched?.masters_degree_year && errors?.masters_degree_year)}
            helperText={
              touched?.masters_degree_year && <span dangerouslySetInnerHTML={{ __html: errors?.masters_degree_year }} />
            }
          />

          <TextField
            fullWidth
            inputProps={{ type: 'number' }}
            label={labelDoutoradoField}
            {...getFieldProps('doctorate_degree_year')}
            error={Boolean(touched?.doctorate_degree_year && errors?.doctorate_degree_year)}
            helperText={
              touched?.doctorate_degree_year && (
                <span dangerouslySetInnerHTML={{ __html: errors?.doctorate_degree_year }} />
              )
            }
          />

          <FormGroup style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
            <FormControlLabel
              control={<Checkbox checked={doubleCheckbox === 1} onChange={(e) => handleChange(e)} />}
              label="Está  empregado atualmente?"
            />
            <FormControlLabel
              control={<Checkbox checked={doubleCheckbox === 2} onChange={(e) => handleCheckbox(e)} />}
              label="Aposentado"
            />
          </FormGroup>
          {checkBox === 1 && (
            <>
              {doubleCheckbox === 2 && (
                <Alert severity="info">
                  As informações abaixo são referentes ao seu último local de trabalho onde se aposentou.
                </Alert>
              )}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Área</InputLabel>
                <Select
                  labelId="age-select-label"
                  id="age-select"
                  label="Age"
                  onChange={(e) => {
                    if (checkBox) {
                      setFieldValue('job_area', e.target.value.toUpperCase())
                    }
                  }}
                  error={Boolean(touched?.job_area && errors?.job_area)}
                  defaultValue=""
                >
                  <MenuItem value="Pública">Pública</MenuItem>
                  <MenuItem value="Privada">Privada</MenuItem>
                </Select>

                {errors?.job_area && (
                  <FormHelperText error id="my-helper-text">
                    Selecione uma área
                  </FormHelperText>
                )}
              </FormControl>
              <TextField
                fullWidth
                label="Empresa ou Instituição"
                {...getFieldProps('job_company')}
                onChange={(e) => {
                  if (checkBox) {
                    setFieldValue('job_company', e.target.value)
                  }
                }}
                error={Boolean(touched?.job_company && errors?.job_company)}
                helperText={touched.job_company && <span dangerouslySetInnerHTML={{ __html: errors?.job_company }} />}
              />
              <ComboBox
                value={areaActivity}
                label="Área de atuação"
                name="area_activity_id"
                loading={loadingOccupations}
                data={occupations}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => {
                  setOccupationQuery('')
                  setOpen(false)
                }}
                onChange={(_, newValue) => {
                  setAreaActivity(newValue)
                  setFieldValue('area_activity_id', newValue?.id)
                }}
                onInputChange={(e, value) => setOccupationQuery(value)}
                helperText={
                  touched.area_activity_id && <span dangerouslySetInnerHTML={{ __html: errors?.area_activity_id }} />
                }
                error={Boolean(touched?.area_activity_id && errors?.area_activity_id)}
              />
              <GoogleSearch
                setFieldValue={setFieldValue}
                doubleCheckbox={doubleCheckbox}
                error={Boolean(errors?.job_city || errors?.job_country)}
                helperText={
                  errors?.job_city ||
                  (errors?.job_country && (
                    <span dangerouslySetInnerHTML={{ __html: errors?.job_city || errors?.job_country }} />
                  ))
                }
                touched={touched}
                errors={errors}
              />
            </>
          )}

          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={handlePermission} />}
              label="Permito a exibição dos meus dados publicamente"
            />
          </FormGroup>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={!formik.isValid}
            loading={isSubmitting}
          >
            Registrar
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  )
}
