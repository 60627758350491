import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Stack, Link, IconButton, Drawer, List, ListItem, ListItemText, Box, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import Logo from '../Logo'

// Estilos para o menu tradicional em telas grandes
const UlStyle = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 40,
  height: '100%',
  alignItems: 'center',
  margin: 0,
  padding: 0,
  listStyleType: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

const ListStyle = styled('li')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}))

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.menu,
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.primary.main
  },
  '&.active': {
    fontWeight: 700,
    color: theme.palette.primary.main
  },
  '&:last-child': {
    marginRight: 0
  }
}))

// ----------------------------------------------------------------------

export default function MainMenu() {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open)
  }

  // Lista de navegação para o menu de hambúrguer
  const menuList = (
    <Box sx={{ width: 200 }}>
      <Stack display="flex" justifyContent="center" alignItems="center" paddingY={2}>
        <Logo />
      </Stack>
      <Divider />
      <List>
        <ListItem button component={RouterLink} to="/" onClick={toggleDrawer(false)}>
          <ListItemText primary="Início" />
        </ListItem>
        <ListItem button component={RouterLink} to="/register" onClick={toggleDrawer(false)}>
          <ListItemText primary="Cadastre-se" />
        </ListItem>
        <ListItem button component={RouterLink} to="/login" onClick={toggleDrawer(false)}>
          <ListItemText primary="Entrar" />
        </ListItem>
      </List>
    </Box>
  )

  return (
    <>
      {/* Menu tradicional para telas maiores */}
      <Stack component="nav" direction="row" spacing={2}>
        <UlStyle>
          <ListStyle>
            <LinkStyle to="/" component={RouterLink}>
              Início
            </LinkStyle>
          </ListStyle>
          <ListStyle>
            <LinkStyle to="/register" component={RouterLink}>
              Cadastre-se
            </LinkStyle>
          </ListStyle>
          <ListStyle>
            <LinkStyle to="/login" component={RouterLink}>
              Entrar
            </LinkStyle>
          </ListStyle>
        </UlStyle>
      </Stack>

      {/* Botão de menu hambúrguer para telas menores */}
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        sx={{ display: { md: 'none' } }} // Mostra apenas em telas menores
      >
        <MenuIcon />
      </IconButton>

      {/* Drawer para o menu de hambúrguer */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {menuList}
      </Drawer>
    </>
  )
}
