import React, { useState } from 'react'
// material
import { Stack, Container, Typography, Box, Tab } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
// components
import Page from '../components/Page'
import EditarEgresso from './EditEgresso'
import UserForm from '../components/_dashboard/usuarios/UserForm'

import { useMe } from '../Context/MeContext'

const Profile = () => {
  const { userInfo, meLoading, role } = useMe()
  const [value, setValue] = useState(role !== 'admin' ? '1' : '2')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <Page title="Perfil">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Perfil
          </Typography>
        </Stack>

        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                {role !== 'admin' && <Tab label="Cadastro" value="1" />}
                <Tab label="Acesso" value="2" />
              </TabList>
            </Box>
            {role !== 'admin' && (
              <TabPanel value="1">
                <EditarEgresso inProfile meLoading={meLoading} />
              </TabPanel>
            )}

            <TabPanel value="2">
              <UserForm inProfile userInfo={userInfo} meLoading={meLoading} />
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </Page>
  )
}

export default Profile
