import PropTypes from 'prop-types'
// material
import { Container, Toolbar } from '@mui/material'
import { styled } from '@mui/material/styles'
// components
import Logo from '../../components/Logo'
import MainMenu from '../../components/menus/MainMenu'
import LogoUniversity from '../../components/LogoUniversity'
//

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  boxSizing: 'border-box',
  flexShrink: 0,
  position: 'sticky',
  top: 0,
  left: 'auto',
  right: 0,
  color: 'inherit',
  backgroundImage: 'none',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  zIndex: 1,
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
  padding: theme.spacing(2, 0),
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(2)
}))

const HeaderLeftStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '90%'
  }
}))

const HeaderRightStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '10%'
  }
}))

const ContentStyle = styled('div')(() => ({
  width: '100%',
  margin: 'auto',
  display: 'flex',
  height: '100%',
  flexDirection: 'column'
}))

// ----------------------------------------------------------------------

MenuLayout.propTypes = {
  children: PropTypes.node
}

export default function MenuLayout({ children }) {
  return (
    <Container maxWidth="xl">
      <HeaderStyle>
        <Toolbar disableGutters>
          <HeaderLeftStyle>
            <Logo />
            <div style={{ marginLeft: 16 }}>
              <LogoUniversity />
            </div>
          </HeaderLeftStyle>
          <HeaderRightStyle>
            <MainMenu />
          </HeaderRightStyle>
        </Toolbar>
      </HeaderStyle>
      <ContentStyle>{children}</ContentStyle>
    </Container>
  )
}
