import { Link as RouterLink } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
import { Link, Container, Typography } from '@mui/material'
// layouts
// components
import Page from '../components/Page'
import { ForgotForm } from '../components/authentication/forgot'
import AuthSidebar from '../layouts/AuthSidebar'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}))

// ----------------------------------------------------------------------

export default function Forgot() {
  return (
    <RootStyle title="Redefinir Senha">
      <AuthSidebar>
        <Typography variant="h4" sx={{ px: 10 }} align="center">
          Bem vindo,
        </Typography>
        <Typography variant="h1" sx={{ px: 10 }} align="center">
          Portal Egressos
        </Typography>
      </AuthSidebar>

      <Container maxWidth="sm">
        <ContentStyle>
          <Typography variant="body1" align="center" sx={{ mb: 3 }}>
            Informe o seu e-mail de cadastro para receber as instruções de redefinição de senha.
          </Typography>
          <ForgotForm />
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            <Link variant="subtitle2" component={RouterLink} to="/login">
              Lembrou? Clique aqui para entrar.
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  )
}
