import React from 'react'
import { Icon } from '@iconify/react'
// material
import { alpha, styled } from '@mui/material/styles'
import { Card, CircularProgress, Typography } from '@mui/material'
// utils
import { fShortenNumber } from '../../../../utils/formatNumber'
import { dashboardApi } from '../../../../api/dashboard'

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.dark,
  backgroundColor: theme.palette.warning.light
}))

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.warning.light, 0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24
  )} 100%)`
}))

// ----------------------------------------------------------------------

export default function TotalEgressosMestrado() {
  const [total, setTotal] = React.useState(0)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    getTotal()
  }, [])

  async function getTotal() {
    try {
      setLoading(true)
      const resp = await dashboardApi.getTotalEgressosMestrado()
      setTotal(resp.total)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon="icon-park-solid:degree-hat" width={32} height={32} />
      </IconWrapperStyle>
      {loading && <CircularProgress color="warning" />}
      {!loading && <Typography variant="h3">{fShortenNumber(total)}</Typography>}
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Mestrado
      </Typography>
    </RootStyle>
  )
}
