import { Link as RouterLink } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
import { Link, Typography, Box, Stack } from '@mui/material'
// components
import Page from '../components/Page'
import MapGoogle from '../components/googleMap/GoogleMap'
import Signature from '../components/signature'
import MenuLayout from '../layouts/site/MenuLayout'
import { useSettings } from '../Context/SettingsContext'
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(() => ({
  display: 'block'
}))

// ----------------------------------------------------------------------

export default function Mapa() {
  const { settingsInfo } = useSettings()

  const departmentName = settingsInfo?.department_name
  const universityAcronym = settingsInfo?.university_acronym
  const departmentUnivertText = departmentName
    ? `${departmentName}${universityAcronym ? `-${universityAcronym}` : ''}`
    : ''

  const imageDepartament = settingsInfo?.department_image
  const imageUniversity = settingsInfo?.university_image

  return (
    <MenuLayout>
      <RootStyle title="Mapa">
        {/* <AuthSidebar>
        <Typography variant="h4" sx={{ px: 10 }} align="center">
          Bem vindo,
        </Typography>
        <Typography variant="h1" sx={{ px: 10 }} align="center">
          Portal Egressos
        </Typography>
      </AuthSidebar> */}

        <Typography variant="body2">Bem vindo ao,</Typography>
        <Typography variant="h3" component="h1" sx={{ lineHeight: 1 }}>
          Portal de Egressos
        </Typography>
        {departmentUnivertText && (
          <Typography variant="button" component="h2" sx={{ lineHeight: 2 }}>
            {departmentUnivertText}
          </Typography>
        )}
        <Box sx={{ marginTop: { xs: '1rem' } }}>
          {/* <Typography variant="h4">Mapa de Egressos</Typography> */}
          <Typography variant="body2">
            Digite o nome de uma cidade ou país e localize nossos Egressos pelo mundo. Você também é nosso Egresso?
            &nbsp;
            <Link to="/register" component={RouterLink} sx={{ fontWeight: 500 }}>
              Faça aqui seu cadastro
            </Link>
            .
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between" height={600} marginTop={2}>
          <MapGoogle />
        </Stack>
        {/* <Signature /> */}
      </RootStyle>
    </MenuLayout>
  )
}
