/* eslint-disable consistent-return */
// ----------------------------------------------------------------------
export const egressoList = (data) => {
  try {
    const list = data.map((item) => ({
      id: item.id,
      full_name: item.full_name,
      email: item.email,
      masters_degree_year: item.masters_degree_year,
      doctorate_degree_year: item.doctorate_degree_year,
      job_area: item.job_area,
      area_activity: item.area_activity,
      local:
        item.job_country === 'Brasil'
          ? `${item.job_city}-${item.job_state},${item.job_country}`
          : `${item.job_country}`,
      status: item.status,
      is_retired: item.is_retired
    }))
    return list
  } catch (error) {
    console.log(error)
  }
}
