import React from 'react'
import { merge } from 'lodash'
import ReactApexChart from 'react-apexcharts'
// material
import { Box, Card, CardHeader, CircularProgress } from '@mui/material'
// util
import { fNumber } from '../../../../utils/formatNumber'
//
import { BaseOptionChart } from '../../../charts'
import { dashboardApi } from '../../../../api/dashboard'

// ----------------------------------------------------------------------

export default function EgressosByCountry() {
  const [chartData, setChartData] = React.useState([{ data: [] }])
  const [categories, setCategories] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    getChartData()
  }, [])

  async function getChartData() {
    try {
      const resp = await dashboardApi.getTotalCountry()
      const jobCountries = resp.map((item) => item.job_country)
      const totals = resp.map((item) => {
        return { x: item.job_country, y: item.total }
      })

      setCategories(jobCountries)
      setChartData([{ data: totals }])
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => ``
        }
      }
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '28%', borderRadius: 2 }
    },
    xaxis: {
      categories
    }
  })

  return (
    <Card>
      <CardHeader title="Egressos pelo mundo" />
      <Box sx={{ mx: 3 }} dir="ltr">
        {loading && <CircularProgress color="info" />}
        {!loading && <ReactApexChart type="bar" series={chartData} options={chartOptions} height={390} />}
      </Box>
    </Card>
  )
}
