import { useRef } from 'react'
import { Box, IconButton, CircularProgress } from '@mui/material'
import { PhotoCamera } from '@mui/icons-material'

const ImageSelector = ({ img, isLoading, handleImageChange }) => {
  const fileInputRef = useRef(null)
  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
      width="100%"
      height="100%"
      sx={{
        backgroundImage: `url(${img || '/static/noImage.png'})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative'
      }}
    >
      {isLoading && (
        <Box position="absolute" width="100%" height="100%" zIndex={2} bgcolor="rgba(0, 0, 0, 0.5)">
          <Box position="absolute" top="50%" left="50%" sx={{ transform: 'translate(-50%, -50%)' }}>
            <CircularProgress />
          </Box>
        </Box>
      )}
      <Box
        position="relative"
        width="100%"
        height="100%"
        sx={{
          '&:hover .file-input': {
            opacity: 1,
            pointerEvents: 'auto'
          }
        }}
      >
        <Box
          className="file-input"
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bgcolor="rgba(0, 0, 0, 0.5)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            opacity: 0,
            transition: 'opacity 0.3s',
            pointerEvents: 'none'
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
            <IconButton color="primary" aria-label="upload picture" onClick={triggerFileInput}>
              <PhotoCamera fontSize="large" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ImageSelector
