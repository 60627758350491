import React, { useCallback, useEffect, useRef, useState } from 'react'

// Imports
//  >Components
import { GoogleMap, useLoadScript, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api'
import { styled } from '@mui/material/styles'

import { CircularProgress, Typography, Box, Grid } from '@mui/material'
import Locate from './locateButton/locate'

// Map style
import mapStyle from './mapStyle'

// Search box with google places
import Search from './searchBox/Search'

import loadMapEgressos from '../../api/loadMapEgressos'

import { useSettings } from '../../Context/SettingsContext'

const InfoWindowContent = styled('div')(() => ({
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))

// Google map's width and height
const mapContainerStyle = {
  width: '100%',
  height: '100%',
  margin: '0 auto',
  borderRadius: '10px'
}

const ErrorMessage = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}))

// Initial location for the map
const center = { lat: 3.7501971, lng: -7.6720608 }

// User's location when allowed to get his location
const userLocation = JSON.parse(localStorage.getItem('location'))

// Map options, such as styles, etc
const options = {
  styles: mapStyle,
  disableDefaultUI: true.valueOf,
  zoomControl: true
  /* mapTypeControl: true */
}

const libraries = ['places']

export default function MapGoogle() {
  const { settingsInfo } = useSettings()
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'pt-BR'
  })

  const [mapData, setMapData] = useState([])
  const [isMapDataLoaded, setIsMapDataLoaded] = useState(false)
  const [errorWhenLoadingEgressos, setErrorWhenLoadingEgressos] = useState(false)
  const loadDataMap = async () => {
    try {
      let dt = []
      const mapEgressos = await loadMapEgressos()
      dt = mapEgressos.data
      for (let i = 0; i < dt.length; i += 1) {
        dt[i].latitude -= Math.random() / 250
        dt[i].longitude -= Math.random() / 250
      }
      setMapData(dt)
      setIsMapDataLoaded(true)
      setErrorWhenLoadingEgressos(false)
    } catch (error) {
      setErrorWhenLoadingEgressos(true)
    }
  }

  useEffect(() => {
    loadDataMap()
  }, [])

  const [selected, setSelected] = useState(null)
  const mapRef = useRef()
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, [])

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng })
    mapRef.current.setZoom(14)
  }, [])

  if (loadError || errorWhenLoadingEgressos) {
    return (
      <ErrorMessage>
        <h3>Nao foi possivel carregar o mapa</h3>
      </ErrorMessage>
    )
  }
  if (!isLoaded || !isMapDataLoaded)
    return (
      <ErrorMessage>
        <CircularProgress />
        <h3>Carregando mapa...</h3>
      </ErrorMessage>
    )
  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={window.innerHeight <= 800 ? 2 : 3}
      center={userLocation === null ? center : userLocation}
      options={options}
      onLoad={onMapLoad}
    >
      <Search panTo={panTo} />
      <Locate panTo={panTo} />
      <MarkerClusterer
        options={{
          averageCenter: true
        }}
        maxZoom={13}
      >
        {(clusterer) =>
          mapData.map((item, index) => (
            <Marker
              key={index}
              clusterer={clusterer}
              position={{
                lat: item.latitude,
                lng: item.longitude
              }}
              icon={{
                url: '/pin.png',
                scaledSize: new window.google.maps.Size(50, 50),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(15, 15)
              }}
              onClick={() => setSelected(item)}
            />
          ))
        }
      </MarkerClusterer>
      {selected ? (
        <InfoWindow
          position={{ lat: selected.latitude, lng: selected.longitude }}
          onCloseClick={() => setSelected(null)}
        >
          <InfoWindowContent>
            {selected.allows_display_data_publicly === 1 ? (
              <Box display="flex" alignItems="center" flexDirection="column">
                <Typography fontSize="1rem" variand="body1">
                  {selected.full_name}
                </Typography>
                <Typography fontSize="1rem" variand="body1">
                  {selected.email}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography fontSize="1rem" variand="body1">
                  Para mais informações entrar em contato com a secretaria do programa.
                  {settingsInfo?.department_email && (
                    <>
                      {' '}
                      Email: <a href={`mailto:${settingsInfo?.department_email}`}>{settingsInfo?.department_email}</a>
                    </>
                  )}
                </Typography>
              </Box>
            )}
          </InfoWindowContent>
        </InfoWindow>
      ) : null}
    </GoogleMap>
  )
}
