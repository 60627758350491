import PropTypes from 'prop-types'
// material
import { Box, Typography } from '@mui/material'
import { useSettings } from '../Context/SettingsContext'

// ----------------------------------------------------------------------

LogoUniversity.propTypes = {
  sx: PropTypes.object
}

export default function LogoUniversity({ sx }) {
  const { settingsInfo } = useSettings()

  if (!settingsInfo?.department_image && settingsInfo?.university_acronym)
    return (
      <Typography variant="button" component="span" sx={{ lineHeight: 1 }}>
        {settingsInfo?.university_acronym}
      </Typography>
    )

  if (!settingsInfo?.university_image) return null

  return (
    <Box
      component="img"
      src={settingsInfo?.university_image}
      sx={{ maxHeight: 60, maxWidth: { xs: 125, md: 200 }, ...sx }}
    />
  )
}
