import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik, Form, FormikProvider } from 'formik'
// material
import { Stack, Card, Container, Typography, Alert, Grid, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useSnackbar } from 'notistack'
// components
import Page from '../components/Page'
import ImageSelector from '../components/ImageSelector'
import { useSettings } from '../Context/SettingsContext'
import { updateSettings, updateImgsSettings } from '../components/_dashboard/configuracoes/requests'

const SettingsSchema = Yup.object().shape({
  university_acronym: Yup.string().nullable(),
  university_name: Yup.string().nullable(),
  university_image: Yup.string().nullable(),
  department_acronym: Yup.string().nullable(),
  department_name: Yup.string().nullable(),
  department_image: Yup.string().nullable(),
  department_email: Yup.string().email('O e-mail deve ser um endereço de e-mail válido').nullable(),
  favicon: Yup.string().nullable()
})

const Configuracoes = () => {
  const { settingsInfo, setSettingsInfo, settingsLoading } = useSettings()
  const [reqErr, setReqErr] = useState(false)
  const [imgsFieldsLoading, setImgsFieldsLoading] = useState([])

  const { enqueueSnackbar } = useSnackbar()
  const handleSnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant
    })
  }

  const handleImageChange = async (e, field) => {
    try {
      setImgsFieldsLoading((prev) => [...prev, field])
      const file = e.target.files[0] ?? null
      if (file) {
        const res = await updateImgsSettings({ [field]: file })
        setFieldValue(field, res.data?.[field])
        handleSnackBar('Imagem atualizada com sucesso!', 'success')
        setSettingsInfo(res.data)
        setImgsFieldsLoading((prev) => prev.filter((f) => f !== field))
      }
    } catch (err) {
      setImgsFieldsLoading((prev) => prev.filter((f) => f !== field))
      if (err.response.data.errors) {
        setErrors(err.response.data.errors)
      }
      handleSnackBar('Erro ao salvar imagem!', 'error')
    }
  }

  const formik = useFormik({
    initialValues: settingsInfo,
    validationSchema: SettingsSchema,
    onSubmit: async () => {
      try {
        const data = {
          university_acronym: values.university_acronym,
          university_name: values.university_name,
          department_acronym: values.department_acronym,
          department_name: values.department_name,
          department_email: values.department_email
        }
        const res = await updateSettings(data)
        handleSnackBar('Configurações editadas com sucesso!', 'success')
        setSettingsInfo(res.data)
        setReqErr(false)
      } catch (err) {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors)
        } else {
          setReqErr(true)
        }
        handleSnackBar('Erro ao editar as configurações!', 'error')
      }
    }
  })

  const { errors, values, setErrors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik

  return (
    <Page title="Configurações">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Configurações
          </Typography>
        </Stack>

        <Grid>
          <Card sx={{ p: 3 }}>
            {!settingsLoading && (
              <FormikProvider value={formik}>
                {reqErr && (
                  <Alert variant="filled" severity="error" style={{ marginBottom: '32px' }}>
                    Ocorreu um erro inesperado, por favor tente novamente mais tarde.
                  </Alert>
                )}
                <Grid container item xs={12} mb={4}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      Universidade
                    </Typography>
                    <Card sx={{ width: 200, height: 200 }}>
                      <ImageSelector
                        img={values?.university_image}
                        isLoading={imgsFieldsLoading.includes('university_image')}
                        handleImageChange={(e) => handleImageChange(e, 'university_image')}
                      />
                    </Card>
                    {errors?.university_image && (
                      <Typography variant="caption" sx={{ color: 'error.main', mt: 1 }}>
                        {errors?.university_image}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      Departamento
                    </Typography>
                    <Card sx={{ width: 200, height: 200 }}>
                      <ImageSelector
                        img={values?.department_image}
                        isLoading={imgsFieldsLoading.includes('department_image')}
                        handleImageChange={(e) => handleImageChange(e, 'department_image')}
                      />
                    </Card>
                    {errors?.department_image && (
                      <Typography variant="caption" sx={{ color: 'error.main' }}>
                        {errors?.department_image}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      Favicon
                    </Typography>
                    <Card sx={{ width: 200, height: 200 }}>
                      <ImageSelector
                        img={values?.favicon}
                        isLoading={imgsFieldsLoading.includes('favicon')}
                        handleImageChange={(e) => handleImageChange(e, 'favicon')}
                      />
                    </Card>
                    {errors?.favicon && (
                      <Typography variant="caption" sx={{ color: 'error.main' }}>
                        {errors?.favicon}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        label="Sigla da Universidade"
                        {...getFieldProps('university_acronym')}
                        value={values?.university_acronym || ''}
                        error={Boolean(touched?.university_acronym && errors?.university_acronym)}
                        helperText={
                          touched?.university_acronym && (
                            <span dangerouslySetInnerHTML={{ __html: errors?.university_acronym }} />
                          )
                        }
                        onChange={(e) => {
                          setFieldValue('university_acronym', e.target.value || null)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        label="Nome da Universidade"
                        {...getFieldProps('university_name')}
                        value={values?.university_name || ''}
                        error={Boolean(touched?.university_name && errors?.university_name)}
                        helperText={
                          touched?.university_name && (
                            <span dangerouslySetInnerHTML={{ __html: errors?.university_name }} />
                          )
                        }
                        onChange={(e) => {
                          setFieldValue('university_name', e.target.value || null)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        label="Sigla do Departamento"
                        {...getFieldProps('department_acronym')}
                        value={values?.department_acronym || ''}
                        error={Boolean(touched?.department_acronym && errors?.department_acronym)}
                        helperText={
                          touched?.department_acronym && (
                            <span dangerouslySetInnerHTML={{ __html: errors?.department_acronym }} />
                          )
                        }
                        onChange={(e) => {
                          setFieldValue('department_acronym', e.target.value || null)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        label="Nome do Departamento"
                        {...getFieldProps('department_name')}
                        value={values?.department_name || ''}
                        error={Boolean(touched?.department_name && errors?.department_name)}
                        helperText={
                          touched?.department_name && (
                            <span dangerouslySetInnerHTML={{ __html: errors?.department_name }} />
                          )
                        }
                        onChange={(e) => {
                          setFieldValue('department_name', e.target.value || null)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        type="email"
                        label="Endereço de e-mail do Departamento"
                        {...getFieldProps('department_email')}
                        value={values?.department_email || ''}
                        error={Boolean(touched?.department_email && errors?.department_email)}
                        helperText={
                          touched?.department_email && (
                            <span dangerouslySetInnerHTML={{ __html: errors?.department_email }} />
                          )
                        }
                        onChange={(e) => {
                          setFieldValue('department_email', e.target.value || null)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="space-between">
                        <LoadingButton
                          size="large"
                          type="submit"
                          variant="contained"
                          disabled={isSubmitting}
                          loading={isSubmitting}
                        >
                          Salvar
                        </LoadingButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            )}
          </Card>
        </Grid>
      </Container>
    </Page>
  )
}

export default Configuracoes
