import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { forwardRef } from 'react'
// material
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

import { useSettings } from '../Context/SettingsContext'

const Page = forwardRef(({ children, title = '', ...other }, ref) => {
  const { settingsInfo } = useSettings()
  const pageTitle = settingsInfo?.department_name
    ? `${title} | ${settingsInfo?.department_name} ${
        settingsInfo?.university_acronym && settingsInfo?.department_name ? `- ${settingsInfo?.university_acronym}` : ''
      }`
    : `${title} | Meu Egressos`

  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {children}
    </Box>
  )
})

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
}

export default Page
