export const changeFavicons = (newFavicons) => {
  const faviconSelectors = [
    { rel: 'apple-touch-icon', size: '180x180' },
    { rel: 'icon', type: 'image/png', size: '32x32' },
    { rel: 'icon', type: 'image/png', size: '16x16' }
  ]

  faviconSelectors.forEach((favicon, index) => {
    const element = document.querySelector(`link[rel='${favicon.rel}'][sizes='${favicon.size}']`)
    if (element) {
      element.href = newFavicons[index]
    }
  })
}
