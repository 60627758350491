import { useEffect } from 'react'
import { useLocation, Link as RouterLink, useNavigate } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
import { Box, Link, Container, Typography } from '@mui/material'
// layouts
import AuthSidebar from '../layouts/AuthSidebar'
// components
import Page from '../components/Page'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const ContentStyle = styled('div')(({ theme }) => ({
  '& .MuiTypography-root': {
    margin: '20px 0'
  },
  textAlign: 'center',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}))

// ----------------------------------------------------------------------

export default function Registrado() {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (!location.state) {
      navigate('/login', { redirect: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {location.state != null && (
        <RootStyle title="Registro Concluído">
          <AuthSidebar>
            <Typography variant="h1" sx={{ px: 10 }} align="center">
              Cadastro de <br /> Egresso
            </Typography>
          </AuthSidebar>

          <Container maxWidth="md">
            <ContentStyle>
              <Typography variant="h2">Obrigado pelo seu registro!</Typography>
              <Typography variant="h4" component="p" sx={{ mb: 3, fontWeight: '400' }}>
                Enviamos sua senha de acesso para o email{' '}
                <Box sx={{ color: 'primary.main' }} component="span">
                  {location.state.email}.
                </Box>
              </Typography>
              <Typography variant="h4" component="p" sx={{ mb: 3, fontWeight: '400' }}>
                Para acessar a plataforma{' '}
                <Link to="/login" component={RouterLink}>
                  clique aqui
                </Link>
              </Typography>
            </ContentStyle>
          </Container>
        </RootStyle>
      )}
    </>
  )
}
