import Api from '../../../../api/api'

export const updateSettings = async (data) => {
  const settings = await Api.post(`/settings`, data)
  return settings
}

export const updateImgsSettings = async (data) => {
  const formData = new FormData()
  Object.entries(data).forEach(([key, value]) => formData.append(key, value))
  const img = await Api.post(`/settings`, formData)
  return img
}
